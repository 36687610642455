
export default {
  name: 'Card',
  props: ['card','cardId','isGallery'],
  data() {
    return {
    }
  },
  computed: {
    hasLink(){
      return this.card.card_cta_link && (this.card.card_cta_link.url || this.card.card_cta_link.uid)
    },
    isDownloadLink() {
      const url = this.card?.card_cta_link?.url
      return this.hasLink && url && (url.includes('.pdf'))
    },
    isProductPageLink() {
      const url = this.card?.card_cta_link?.url
      return this.hasLink && url && url.includes('vitesy.com') && (url.includes('/shelfy') || url.includes('/natede') || url.includes('/eteria'))
    },
    hasIcon(){
      return this.card.card_image && this.card.card_image.url && this.card.card_image.url.includes('svg')
    },
    hasModal(){
      return this.card.card_modal_content && this.card.card_modal_content.length && this.card.card_modal_content[0].text?.length
    },
    hasImage(){
      return this.card.card_image && this.card.card_image.url
    },
    hasVideo(){
      return this.card.card_video && this.card.card_video.embed_url
    },
    isAmazonReview() {
      return this.card.card_class?.includes('amazon-review')
    },
    ctaClasses() {
      let classes = 'btn--grey--light'
      this.isProductPageLink ? classes = 'btn--green' : ''
      this.isDownloadLink ? classes = classes + ' download-button' : ''
      return classes
    }
  }
}
